.list-branch{
    max-height: 500px!important;
    overflow-y: scroll!important;
    & .disabled-row{
        background-color: rgba(155, 155, 155, 0.221) !important;
        border-radius: 6px;
        opacity: .5;
        margin-top: 2px!important;
    }
}
.highlightBill{
    background-color: #f3f3f3;
    box-shadow: 0 0 5px 2px #f3f3f3;
}
.btn-reload{
    margin-right: 10px!important;
    border-radius: 40px!important;

}