.content-field{
    display: flex;
    // margin-bottom: 5%;
    .label{
        width: 30%;
        line-height: 200%;
    }
    div{
        width: 70%;
        // border: 1px solid black;
        // line-height: 150%;
       
        // color: aliceblue;
    }

}