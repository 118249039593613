.ant-upload-picture-card-wrapper.avatar-uploader {

  span.ant-upload {
    position: relative;
  }
}

.avatar-uploader{
  position: relative;
  & .closeTopRight{
    position: absolute;
    top: 0px;
    right: 0px;
  }
}