.custom-modal-responsive{
    & .ant-modal-body{
    }
}

@media only screen and (max-width: 576px) {	
    .custom-modal-responsive{
        & .ant-modal-body{
            padding : unset !important;
            padding-top: 24px!important;
            padding-bottom: 24px!important;
        }
    }
}