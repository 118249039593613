%disf{
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin service-item_1 {

    .service-item-bg{
        position: absolute;
        top: auto;
        right: auto;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        transform: rotate(var(--rotate));
        .service-item-bg_main{
            width: max-content;
            height: max-content;
            margin-right: calc( var(--sizeComponent) / 2 * (-1));
            transform: rotate(calc(var(--rotate) * (-1)));
            filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.712));
        
            .service-item-content{
                width: 100%;
                aspect-ratio: 1/1;
                position: relative;
                animation: re-xoay var(--action) ease-in-out infinite;
                animation-play-state: paused;
                @extend %disf;
                &.animation {         
                    animation-play-state: running;
                }

                &:hover{
                    width: 110%;
                    cursor: pointer;
                }
                transition: all 0.2s ease-in-out;
                .circle{
                    width: var(--sizeComponent );
                    aspect-ratio: 1/1;
                    border-radius: 50%;
                    transition: all 0.5s ease-in-out ;
                }
                .image , .block{
                    @extend %disf;
                    width: var(--sizeBlock );
                    top: auto;
                    right: auto;
                    position: absolute;
                }
                .image{
                    transform: scale(0.8);
                    aspect-ratio: 1/1;
                    img{
                        width: 100%;
                        height : 100%;
                        object-fit: cover;
                    }
                }
                .block{
                    --sizeBlock : calc(var(--sizeComponent) * 1.2);
                    min-width: max-content;
                    font-size: 12px;
                    color: white;
                    padding: 8px 6px;
                    border-radius: 6px;
                    background-color: orange;
                    transition: all 0.5s ease-in-out;
                }
            }
            .service-item-content.active {
                .circle{
                    // background-color: red !important;
                    position: relative;
                    @extend %disf;
                    align-content: center;
                    &::after{
                        content: "";
                        position: absolute;
                        top: auto;
                        right: auto;
                        aspect-ratio: 1/1;
                        animation: focus 5s infinite;
                        transition: all 0.1s ease-in-out;
                        border-radius: 50%;
                    }
                }
            }
        }
        
    }
}
@mixin highlight-navbar {
    .highlight-navbar-container{
        flex-direction: column;
        @extend %disf;
        gap: 6px;
        width:max-content;
        height: max-content;
        margin-left: auto;
        .highlight-navbar-item{
            $width : 80px;
            transition: transform 0.08s ease-in-out;
            position: relative;
            box-shadow: 3px 0 8px rgba(139, 139, 139, 0.664);
            text-align: center;
            width: $width;
            border-radius: 6px;
            background-color: cadetblue;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            aspect-ratio: 4/2;
            cursor: pointer;
            p{
                width: 100%;
            }

            .highlight-navbar-modal{
                position: absolute;
                right: ($width + 15px);
                bottom: 0;
                padding: 10px;
                border-radius: 6px;
                width: minmax(300px, max-content);
                height: minmax(100px , max-content);
                background-color: white;
                box-shadow: 3px 0 8px rgb(99, 99, 99);
                visibility: hidden;
                opacity: 0;
                transform: translateX(-10px);
                transition: transform 0.1s ease-in-out;
                z-index: 999;
                .ant-form-item{
                    margin-bottom: 10px;
                    position: relative;
                }
                .highlight-navbar-modal-btn{
                    position: absolute;
                    bottom: -40px;
                    right: calc(50% - 50px);
                    width: 100px;
                    border-radius: 6px;
                    filter: drop-shadow(0 0 3px #888888);

                }
            }
            .highlight-navbar-modal.active{
                visibility: visible;
                opacity: 1;
                transform: translateX(0px);
                transition: transform 0.1s ease-in-out;                
            }
        }
    }
}

@mixin highlight-content-tree {

    .highlight-content{
        border-radius: 8px;
        background-color: #ffffff;
        filter: drop-shadow(0 0 8px #6666);
        align-self: stretch;
        overflow-y: auto;
        padding: 10px 0;
        .ant-collapse-item{
            .ant-collapse-header{
                padding: 5px;
                text-transform: capitalize;
            }
            .ant-collapse-content-box{
                padding: 0px;
                text-transform: capitalize;
                ul{
                    margin: 0;
                    padding: 0;
                }
                li{
                    padding: 4px;
                    padding-left: 32px;
                    transition: all 0.1s ease-in-out;
                    list-style: inside;
                    &:hover{
                        background-color: rgb(127, 157, 255);
                        cursor: pointer;
                    }
                    &.active {
                        background-color: rgb(127, 157, 255);

                    }
                }
            }
        }
       
    }
}

@mixin highlight-content-input{
    position: relative;
    .highlight-content-input{
        border-radius: 8px;
        filter: drop-shadow(0 0 8px #929292);
        background-color: white;
        padding-top: 16px;
        .submit-btn{
            width: 100px;
            border-radius: 6px;
            margin: 0 2px;
        }
        .submit-btn.delete{
            background-color: #f13e3e;
            color: white;
            &:disabled{
                background-color: #929292;
                color:white;
            }
        }
    }
}
.highlight-page{
    padding: 0 40px;
    .formControl{
        min-width: 250px;
        .form-color{
            width: auto;
            height: max-content;
            display: flex;
            flex-direction: column;
            transition: all 0.1s ease-in-out;
            label {
                width: max-content;
                margin: 10px 0;
            }
            .form-color-main{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;
                flex-grow: 1;
                .form-color-item{
                    width: 32px;
                    border-radius: 4px;
                    // aspect-ratio: 1/1;
                    border: 1px solid black;
                }
                .form-color-item.active{
                    border: 2px solid rgb(154, 154, 228);
                    filter: drop-shadow(0 0 6px #666666)
                }
            }
        }
    }
    .form-color-item{
        min-width: 2.4rem;
        min-height: 2rem;
        // border-radius: 4px;
        background-color: #f13e3e;
        // aspect-ratio: 1/1;
        // border: 1px solid black;
        margin: -4px -8px;
    }
    .form-color-item.active{
        border: 2px solid rgb(154, 154, 228);
        filter: drop-shadow(0 0 6px #666666)
    }

    .highlight-content-container{
        margin-top: 32px;
    }

    @include highlight-content-tree;
    @include highlight-content-input;
}
.highlight-setting-container{
    @include highlight-navbar;
    .highlight-navbar-item--title{
        width: 100%;
        height: 100%;
        border-radius: 6px;
        @extend %disf;
        transition: all 0.1s ease-in-out;
    }
    .highlight-navbar-item--title.active{
        background-color: #1890ff
    }
}


.setting-container {
    // width: 300px;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    @extend %disf;
    align-content: center;
    position: relative;
    // border: 1px solid black;
    
    &>div {
        position: absolute;
        top: auto;
        width: 100px;
        color: black;
        text-align: center;
        @extend %disf;
        left: auto;
    }
    &-main-content{
        z-index: 102;
        @extend %disf;
        // min-width: 100px;
        position: relative;
        &>img {
            z-index: 102;
            width: 100%;
            height : 100%;
            object-fit: cover;
        }
        &:hover{
            cursor: pointer;
        }
        .main-content--background{
            z-index: 99;
            position: absolute;
            top: auto;
            left: auto;
            background-color: white;
            aspect-ratio: 1/1;
            border-radius: 50%;
            width: 105%;
        }
        // overflow: hidden;
        border-radius: 50%;
        aspect-ratio: 1/1;
        transition: all 0.2s ease-in-out;
        background-color: white;
        filter: drop-shadow(0 0 8px #666);
    }

    &>.service-item {
        --size : 60%;
        --sizeComponent : 80px;
        right: auto;
        width: var(--size);
        height: var(--size);
        animation: xoay var(--action) ease-in-out infinite;
        animation-play-state: paused;
        &.animation{
            animation-play-state: running;
        }
        transition: all 0.5s ease-in-out ;

        @include service-item_1;
        svg {
            position: absolute;
            overflow: visible;
            transform: rotate(var(--rotateFirst));
            top: -1px;
            right: -1px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            circle {
                stroke: rgb(0, 153, 255);
                fill: none;
                width: 100%;
                height : 100%;
                stroke-width: 2px;
            }
        }
    }
    &>.service-item:nth-child(2) {
        z-index: 101;
    }
    &>.service-item:nth-child(3) {
        z-index: 100;
    }
    &>.service-item:nth-child(4) {
        z-index: 99;
    }
}

@keyframes xoay{
    0% {
        transform: rotate(0deg);
    }
    20% {
        transform: rotate(40deg);
    }
    40% {
        transform: rotate(180deg);
    }
    60% {
        transform: rotate(0deg);
    }
    90%{
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes re-xoay{
    0% {
        transform: rotate(360deg);
    }
    20% {
        transform: rotate(320deg);
    }
    40% {
        transform: rotate(180deg);
    }
    60% {
        transform: rotate(360deg);
    }
    90% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes focus{
    0% {
        background: radial-gradient(circle, rgba(2,0,36,0) 0%, rgb(36, 116, 143) 0%, rgba(0,212,255,0) 100%);
        width: 100%;
    }

    25% {
        width: 130%;
        background: radial-gradient(circle, rgba(2,0,36,0) 0%, rgba(41,161,201,1) 100%, rgba(0,212,255,0) 100%);
    }
    50% {
        width: 100%;
        background: radial-gradient(circle, rgba(2,0,36,0) 0%, rgba(41,161,201,1) 100%, rgba(0,212,255,0) 100%);
    }
    75% {
        background: radial-gradient(circle, rgba(2,0,36,0) 0%, rgba(41,161,201,1) 100%, rgba(0,212,255,0) 100%);
        width: 130%;
    }
    100% {
        // width: 50px;
        width: 100%;
        background: radial-gradient(circle, rgba(2,0,36,0) 0%, rgba(41,161,201,1) 0%, rgba(0,212,255,0) 100%);
    }
}
@media (max-width: 375px) {
    .setting-container{
        transform: scale(0.68);
        aspect-ratio: 1/2;
    }
}
@media (min-width: 376px) {
    .setting-container{
        transform: scale(0.8);
    }
}
@media (min-width: 450px) {
    .setting-container{
        transform: scale(1);
        
    }
}

