@mixin maxLineText($numberOfLine) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $numberOfLine;
  -webkit-box-orient: vertical;
}


@mixin aspect-ratio-box($ratio) {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: $ratio;
}
