.news-category-form {
  .ant-skeleton {
    width: 100%;
  }

  &__select-langue-btn {
    width: 80px;
    text-align: center;
  }

  &__logo-row {
    margin-top: 30px;
  }

  &__upload-logo {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin-bottom: 24px;

    .ant-upload.ant-upload-select-picture-card {
      margin: 0;
      width: 160px;
      height: 160px;
    }

    .ant-upload-picture-card-wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__section-title {
    margin: 25px 0;
  }

  .ant-input-number {
    width: 100%;
  }

  .ant-picker {
    width: 182px;
  }

  &__images {
    .ant-upload,
    .ant-upload-list-picture-card-container {
      width: 160px;
      height: 160px;
    }
  }

  &__submit-box {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    & > * {
      margin: 0 20px;
      min-width: 100px;
    }

    button {
      min-width: 100px;
    }
  }

  .hiden {
    display: none;
  }

  &__description {
    width: 100%;
  }

  .top-5 {
    position: relative;
    top: -3px;
  }

  &__editor {
    .ant-form-item {
      width: 100%;
    }
  }
}

.stickyHearderBarEditor .ck.ck-editor__top.ck-reset_all .ck-sticky-panel {
    position: fixed!important;
    top: 0!important;
    width: 100%;
    transition: all 0.5s!important;
    z-index: 999!important;
  }
.fixed {
  position: fixed!important;
  top: 0!important;
  width: 100%;
  transition: all 0.5s!important;
  z-index: 999!important;
}
.layoutNews {
  .page-content__left,
  .page-content__right {
    box-shadow: 0 2px 4px 2px rgba(15, 34, 58, 0.12);
    transition: all .2s ease-in-out;
  }
  .page-content__left{
    width: 300px;
    max-width: unset;
    margin-right: 30px;
  }
  .page-content__right {
    flex-grow: 1;
  }

}
