.align-label-right{
    & label{
        justify-content: flex-end;
    }
}
.activityRow{
    position: relative;
    &--buttonPlug{
        position: absolute;
        right: -20px;
        top: 0;
        cursor: pointer;
    }
}
.form-add-activity{
    & .ant-input-group-addon{
        padding: 0!important;
    }
}
.VND-Exchange{
    background-color: #4AAC4E;
    padding: 7px;
    color: white;
    border-radius: 5px;
    font-weight: 700;
}
.POINT-Exchange{
    background-color: #EFAD4D;
    padding: 7px;
    color: white;
    border-radius: 5px;
    font-weight: 700;
}