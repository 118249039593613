.page-wraper.wh-bills-processing-page {
  .page-content__left,
  .page-content__right {
    box-shadow: 0 2px 4px 2px rgba(15, 34, 58, 0.12);
  }

  .page-content__left {
    width: 450px;
    min-width: 400px;
    max-width: 400px;
    margin-right: 40px;
    padding: 20px;
    max-height: calc(100vh - 150px);
    overflow-y: scroll;
  }
  .page-content__right {

  }
}
.disabledRowDiscount{
opacity: 0.4;
}