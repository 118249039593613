.partner-detail {
    &__navbar {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: -20px;
      // border-bottom: 2px solid #f6f6f6;
      margin-bottom: 20px;
    }
  }
  