.home {
    &--title{
        color: #5b72e7!important;
        text-shadow: 0 0 25px white!important;
        display: inline!important;
    }
    // & .ant-collapse{
    //   box-shadow: 0px -8px 14px -15px rgba(77, 77, 77, 0.856) inset !important;
    // }
    &--row{
      // box-shadow: ;
      // max-width: 82vw;
      // overflow-x: scroll;
      // scroll-behavior: smooth; /* Enable smooth scrolling behavior */
      padding: 18px 0;
      &__item{
        min-width: max-content;
        margin-bottom:1rem !important;
        &__dropdownItem{
          padding: 12px 12px!important;
        }
        &__dropdownMenu{
          max-height: 400px !important;
          overflow-y: scroll;
        }
      }
    }
  // &--btnShortcuts {
  //   &.ant-btn {
  //     height: 120px !important;
  //     border-radius: 15px !important;
  //     width: max-content !important;
  //     min-width: 120px!important;
  //     color: #7b8190;
  //     background-color: #ffffff;
  //     transition: all 0.1s;
  //   }
  //   &.ant-btn:hover {
  //     color: #5b72e7;
  //     border-color: #5b72e7;
  //     background-color: #e8f3fd;
  //     & svg {
  //       font-size: 3.2rem;
  //     }
  //   }
  //   & svg{
  //       transition: font-size 0.5s;
  //       font-size: 2.8rem;
  //   }
  // }
}
.overlay-home {
  position: absolute;
  background-image: url('../../assets/images/BG-01.svg');
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // background-color: #e8f3fd;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  &::after{
    content: '';
    top: 15%;
  left: 15%;
  bottom: 0;
  right: 0;
  position: absolute;
  background-image: url('../../assets/images/icon-dashboard.svg');
  background-repeat: no-repeat;
  }
}
// .overlay-home::after{
//   content: '';
//   position: fixed;
//   inset: 0;
//   background: #00000017 !important;
//   -webkit-backdrop-filter: blur(18px) !important;
//   backdrop-filter: blur(18px) !important;    
//   z-index: 10;
// }
.homepage{

  position: relative;
  min-height: calc(100vh - 70px);
  max-height: calc(100vh - 70px);
  & .overlayBlack-home{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.077);
  }//
  &--left{//
    min-width: 200px;
    width: 21%!important;//
    padding-left: unset!important;//
  }//
  &--right{//
    width: 79%!important;//
    padding-left: unset!important;//
  }//
  & .menu-homepage{//
    max-height: calc(100vh - 70px);
    min-height: calc(100vh - 70px);
    overflow-y: scroll;
    padding-bottom: 20px;
    & .ant-menu-title-content{
      -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    }
    & .ant-menu-submenu-title{
      align-items: flex-start!important;
    }
  }
  & .ant-layout{
    background: unset!important;
    width: max-content!important;
  
  }
  & .ant-layout-sider{
    background: #005d9017 !important;
    // background: #3a343480 !important;
    -webkit-backdrop-filter: blur(18px) !important;
    backdrop-filter: blur(18px) !important;
    border-radius: 10px!important;
    overflow: hidden!important;
    max-width: unset!important;
    width: unset!important;
  }
  & .ant-menu{
    background-color: transparent!important;
    // & li:hover + li {
    //   -webkit-transform: scale(1.08);
    
    // }
    // & li.prev {
    //   -webkit-transform: scale(1.08);
    // }
    & li {
      // height: 2.3rem!important;
      // line-height: 2.3rem!important;
      transition: all 0.2s;
      transform-origin: left center;
      
    } 
    & li:hover{
        transition: all 0.2s;
    }
    
    & .anticon, i{
      // transition: all 0.2s;
      margin: unset!important;
      font-size: 18px;
    }
  }
}
.Content-homepage{
  cursor: pointer;
  -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  background-color: white;
  padding: 10px 20px 10px 5px;
  box-sizing: border-box;
  border-radius: 10px;
  &:hover{
        box-shadow: inset 0 -13px 0px -11px #3D7FF3 !important;
  }
  &__title{
    font-size: 14px;
    color: #7b8190;
  }
}
.Content{
  background: #005d9017 !important;
    -webkit-backdrop-filter: blur(18px) !important;
    backdrop-filter: blur(18px) !important;
    padding: 5px 12px 0px 12px!important;
    border-radius: 10px;
  &--header{
    width: 99%;
  }
  .inline{
    max-width: 82vw;
    overflow-x: scroll;
    scroll-behavior: smooth; /* Enable smooth scrolling behavior */
  }
  .wrap{
    max-width: 82vw;
    flex-wrap: wrap;
  }
}

#homepage--drag{
  position: absolute;
  top: 0;
  right: -1.5px;
  bottom: 0;
  background-color: transparent;
  width: 3px;
  transition: .18s background-color ease;
  
  &:hover{
      background-color: #3D7FF3;
      cursor: move;
  }
  & i {
      position: absolute;
      top: 50%;
      z-index: 999;
      max-height: 0px;
      transform:  rotate(90deg);
  }
}