// 
// _horizontal.scss
// 

.topnav {
    background: $topnav-bg;
    padding: 0 calc(#{$grid-gutter-width} / 2);
    box-shadow: $box-shadow;
    left: 0;
    right: 0;
    z-index: 100;
    
    .topnav-menu {
        margin: 0;
        padding: 0;
    }

    .navbar-nav {
        
        .nav-link {
            font-size: 14.5px;
            position: relative;
            padding: 1rem 1.3rem;
            color: $menu-item-color;
            i{
                font-size: 15px;
            }
            &:focus, &:hover{
                color: $menu-item-active-color;
                background-color: transparent;
            }
        }
        
        .dropdown-item{
            color: $menu-item-color;
            &.active, &:hover{
                color: $menu-item-active-color;
                background-color: transparent;
            }
        }
        
        .nav-item{
            .nav-link.active{
                color: $menu-item-active-color;
            }
        }

        .dropdown{
            &.active{
              >a {
                    color: $menu-item-active-color;
                    background-color: transparent;
                }
            }
        }
    }
}

body[data-layout="horizontal"] {
    #page-topbar{
        left: 0;
        @media (min-width: 992px) {
            padding: 0 calc(#{$grid-gutter-width} / 2);
        }
    }

    .navbar-brand-box{
        position: relative;
        width: auto;
        display: block;
        background-color: transparent;

        @media (min-width: 992px) {
            padding-left: 0;
        }
    }

    
    @media (min-width: 992px) {
        .topnav{
            margin-top: 24px;
            margin-bottom: -16px;
            border-radius: 4px;
        }

        .navbar-header {
            padding: 0 calc(#{$grid-gutter-width} / 2);
        }
    }


}

@include media-breakpoint-up(xl) {

    body[data-layout="horizontal"] {
        .container-fluid,
        .navbar-header {
            max-width: 85%;
        }
    }
}

@include media-breakpoint-up(lg) {
    .topnav {

        .dropdown-item {
            padding: .5rem 1.5rem;
            min-width: 180px;
        }

        .dropdown {
            &.mega-dropdown{
                // position: static;
                .mega-dropdown-menu{
                    left: 0px;
                    right: auto;
                }
            }
            .dropdown-menu {
                margin-top: 0;
                border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;

                .arrow-down {
                    &::after {
                        right: 15px;
                        transform: rotate(-135deg) translateY(-50%);
                        position: absolute;
                    }
                }

                .dropdown {
                    .dropdown-menu {
                        position: absolute;
                        top: 0 !important;
                        left: 100%;
                        display: none
                    }
                    .dropdown-menu-setting{
                        top: -30px !important;
                    }
                }
            }
            &:hover {
                >.dropdown-menu {
                    display: block;
                }
            }
        }

        .dropdown:hover>.dropdown-menu>.dropdown:hover>.dropdown-menu {
            display: block
        }
    }

    .navbar-toggle {
        display: none;
    }
}

.arrow-down {
    display: inline-block;

    &:after {
        border-color: initial;
        border-style: solid;
        border-width: 0 0 1px 1px;
        content: "";
        height: .4em;
        display: inline-block;
        right: 5px;
        top: 50%;
        margin-left: 10px;
        transform: rotate(-45deg) translateY(-50%);
        transform-origin: top;
        transition: all .3s ease-out;
        width: .4em;
    }
}


@include media-breakpoint-down(lg) {
    .topnav-menu {
        .navbar-nav {
            li {
                &:last-of-type {
                    .dropdown {
                        .dropdown-menu {
                            right: 100%;
                            left: auto;
                        }
                    }
                }
            }
        }
    }
}


.draw-custom{
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -ms-user-select: none;
    margin-top: 70px;
    &--menu-draw{
    span{
        font-weight: 500!important;
    }
    i {
        margin: unset!important;
    }
    li >div{
        margin: unset!important;
    }
    &__submenu{
        backdrop-filter: blur(18px)!important; /* Làm mờ phần nền */
        & .ant-menu-sub {
            background: #005d9017!important; /* Màu nền trong suốt */
          max-height: 400px!important;
          & li,ul {
              border-radius: 12px;
              transition: unset!important;
          }
          & li:hover {
            background: #005e903c!important; /* Màu nền trong suốt */
            
            & a,span,i{
                color: white!important;
            }
          }
          }
        ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
          }
        
          ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, .5);
            box-shadow: 0 0 1px rgba(255, 255, 255, .5);
          }

        &__pined{
            & span{
                font-size: 12px!important;
            }
        }
    }
    }   
    & .ant-drawer-wrapper-body{
        background: #005d9017; /* Màu nền trong suốt */
        backdrop-filter: blur(18px); /* Làm mờ phần nền */
        height: calc(100% - #{$header-height})
    }
    & .ant-drawer-footer{
        // order: 2!important;
        margin-top: 10px;
    }
    & .ant-drawer-header{
        // order: 2!important;
        // margin-top: 10px;
        background-color: transparent;
        padding: 8px 12px;
    
    }
    & .ant-drawer-content{
        // overflow: unset;
        overflow: unset;
        background-color: transparent;
    }
    & .ant-drawer-body{
        // order: 3!important;
        padding: unset!important;
        // position: relative;
        padding-right: 8px!important;
    }

    #draw-custom--drag{
        position: absolute;
        top: 0;
        right: -1.5px;
        bottom: 0;
        background-color: transparent;
        width: 3px;
        transition: .18s background-color ease;
        
        &:hover{
            background-color: #3D7FF3;
            cursor: move;
        }
        & i {
            position: absolute;
            top: 50%;
            z-index: 999;
            max-height: 0px;
            transform:  rotate(90deg);
        }
    }
}

.navbarPined{
    background-color: white;
    position: relative;
    box-shadow : rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    &--button{
        position: absolute!important;
        top: -9px;
        right: -6px;
    }
}

@media (max-width: 991.98px) {
    body[data-layout="horizontal"] {

        .navbar-brand-box{
            .logo-dark {
                display: $display-block;
                span.logo-sm{
                    display: $display-block;
                }
            }
        
            .logo-light {
                display: $display-none;
                
            }
        }
    }
    
    .topnav {
        position: fixed;
        max-height: 360px;
        overflow-y: auto;
        padding: 0;
        .navbar-nav {
            .nav-link {
                padding: 0.75rem 1.1rem;
            }
        }

        .dropdown {
            .dropdown-menu {
                background-color: transparent;
                border: none;
                box-shadow: none;
                padding-left: 15px;
                &.dropdown-mega-menu-xl{
                    width: auto;
    
                    .row{
                        margin: 0px;
                    }
                }
            }

            .dropdown-item {
                position: relative;
                background-color: transparent;

                &.active,
                &:active {
                    color: $primary;
                }
            }
        }

        .arrow-down {
            &::after {
                right: 15px;
                position: absolute;
            }
        }
    }
}


@include media-breakpoint-up(lg) {

    body[data-layout="horizontal"][data-topbar="light"] {
        .navbar-brand-box{
            .logo-dark {
                display: $display-block;
            }
        
            .logo-light {
                display: $display-none;
            }
        }

        .topnav{
            background-color: $primary;
            .navbar-nav {
        
                .nav-link {
                    color: rgba($white, 0.6);
                    
                    &:focus, &:hover{
                        color: rgba($white, 0.9);
                    }
                }
        
                > .dropdown{
                    &.active{
                    >a {
                            color: rgba($white, 0.9) !important;
                        }
                    }
                }
            }
        }
    }
}

// Colored Topbar 

body[data-layout="horizontal"][data-topbar="colored"] {
    #page-topbar{
        background-color: #3d7ff3;
        box-shadow: none;
    }

    .logo-dark {
        display: none;
    }

    .logo-light {
        display: block;
    }

    .app-search {
    
        .form-control {
            background-color: rgba($topbar-search-bg,0.07);
            color: $white;
        }
        span,
        input.form-control::-webkit-input-placeholder {
            color: rgba($white,0.5);
        }
    }
    .header-item {
        color: $header-dark-item-color;
    
        &:hover {
            color: $header-dark-item-color;
        }
    }

    .navbar-header {
        .dropdown .show {
            &.header-item{
                background-color: rgba($white,0.1);
            }
        }

        .waves-effect .waves-ripple {
            background: rgba($white, 0.4);
        }
    }

    .noti-icon {
        i {
            color: $header-dark-item-color;
        }
    }

}

