.wh-service-form {
  max-width: 1280px;
  margin: 0 auto;

  .ant-form {
    padding: 50px 40px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
  }

  .ant-skeleton {
    width: 100%;
  }

  &__select-langue-btn {
    width: 80px;
    text-align: center;
  }

  .ant-upload.ant-upload-select-picture-card {
    margin: 0;
    width: 160px;
    height: 160px;
  }

  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: flex-start;
  }

  &__section-title {
    margin: 25px 0;
  }

  .ant-input-number {
    width: 100%;
  }

  .ant-picker {
    width: 182px;
  }

  &__images {
    .ant-upload,
    .ant-upload-list-picture-card-container {
      width: 160px;
      height: 160px;
    }
  }

  &__submit-box {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    & > * {
      margin: 0 20px;
      min-width: 100px;
    }

    button {
      min-width: 100px;
    }
  }

  .hiden {
    display: none;
  }

  &__description {
    width: 100%;
  }

  .top-5 {
    position: relative;
    top: -3px;
  }

  &__question {
    &--option {
      .ant-input {
        width: 160px;
      }
    }

    &--btn-add-option {
      width: 160px !important;
      height: 160px !important;
    }
  }
}
.course-status {
  height: 22px;
  min-width: 22px;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  padding: 0px 8px;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 8px;
  &.NEW {
    background-color: #f0f0f0;
    color: darken($color: #f0f0f0, $amount: 50%);
  }
  &.SUBMITED {
    background-color: #f0f0f0;
    color: darken($color: #f0f0f0, $amount: 50%);
  }
  &.APPROVED {
    background-color: #52c41a;
    color: #fff;
  }
  &.DENIED {
    background-color: #f5222d;
    color: #fff;
  }
}
.RowAddBouns{
  position: relative;
}
.buttonRightAbsolute{
  position: absolute!important;
  right: -30px;
  padding-left: 5px!important;
  padding-right: 5px!important;
  // top: 45%;
}
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.rowInfo{
    margin-bottom: 10px;
}
.course-status {
    height: 22px;
    min-width: 22px;
    line-height: 0;
    border-radius: 8px;
    cursor: default;
    align-items: center;
    white-space: nowrap;
    display: inline-flex;
    justify-content: center;
    padding: 0px 8px;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 8px;
    &.NEW {
      background-color: #f0f0f0;
      color: darken($color: #f0f0f0, $amount: 50%);
    }
    &.SUBMITTED {
      background-color: #1890ff;
      color: #fff;
    }
    &.APPROVED {
      background-color: #52c41a;
      color: #fff;
    }
    &.DENIED {
      background-color: #f5222d;
      color: #fff;
    }
  }
  .rowInfo{
    margin-bottom: 10px;
}
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  // height: 30px!important;
  cursor: pointer;
}
.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.spaceLeft label{
  margin-left: 20px!important;
}
