.wh-appointments-by-block-table {
  .completed-row {
    background-color: #f6ffed; // green-1
  }
  .in-progress-row {
    background-color: #e6f7ff; // blue-1
  }
  .cancelled-row {
    background-color: #fff1f0; // red-1
  }
}
.ant-table-thead {
  th {
    white-space: nowrap;
  }
}
.desciptionBox{
  &-title{
    text-align: left;
    & .ant-row{
      & .ant-tag{
        width: 50px;
        height: 20px;
      }
      align-items: center;
      margin: 2px 0;
    }
  }
  &-action{
    justify-content: end;
    align-items: center;
  }
}

.tagProduct{
  width: 200px!important;
  max-width: 200px!important;
  white-space: normal!important;
}

.tagConfirmVoucher{
  width: 100px!important;
  max-width: 100px!important;
  white-space: normal!important;
}
.ant-modal-wrap{
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2100;
}


.updateRating {
  width: 50px;
  &--img:hover{
    transform: scale(1.1);
    transition: 0.3s;
  }
}