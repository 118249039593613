// .ant-modal{
//   height: 400px !important;
//   .ant-modal-content{
//     height: 100%;
//     width: 100%;
//     .ant-modal-body{
//       height: 100%;
//       width: 100%;
//     }
//   }
// }
.warehouse{
  &__form{
    height: 100% !important;
  }
  &__button-product{
    position: relative;
    top: 40px;
    margin: 0 auto;
    width: 80%;
    justify-content: space-evenly;
    & button{
        width: 100%;
    }
  }
  &__button-equipment{
    position: relative;
    top: 40px;
    margin: 0 auto;
    width: 80%;
    justify-content: space-evenly;
    & button{
        width: 100%;
    }
  }
}

.select-date{
  margin-right: 20px;
  margin-top: 20px;
  .ant-row{
    margin-right: 40px !important;
    .ant-form-item{
      margin-bottom: 0px!important;
    }
  }
}
.ant-input-number-handler-wrap{
  display: none;
}


.confirm-voucher-status {
  height: 22px;
  min-width: 22px;
  max-width: max-content;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  padding: 0px 8px;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 8px;
  &.new {
    background-color: #f0f0f0;
    color: darken($color: #f0f0f0, $amount: 50%);
  }
  &.confirm {
    background-color: #1890ff;
    color: #fff;
  }
  &.completed {
    background-color: #52c41a;
    color: #fff;
  }
  &.cancel {
    background-color: #f5222d;
    color: #fff;
  }
  &.reject {
    background-color: #f5222d;
    color: #fff;
  }
}

.delivery-voucher-status {
  height: 22px;
  min-width: 22px;
  max-width: max-content;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  align-items: center;
  align-content: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  padding: 0px 8px;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  &.new {
    background-color: #f0f0f0;
    color: darken($color: #f0f0f0, $amount: 50%);
  }
  &.in_progress {
    background-color: #1890ff;
    color: #fff;
  }
  &.completed {
    background-color: #52c41a;
    color: #fff;
  }
  &.cancel {
    background-color: #f5222d;
    color: #fff;
  }
};