.list-custom{
    &--hover{
        & li.ant-list-item{
            cursor: pointer;
        }
        & li.ant-list-item:hover{
            background-color: #F8F8F8!important;
        }
    }
}
.empty-list{
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}