.appointment-table-header {
  display: flex;
  width: 100%;
  overflow: hidden;

  &__submit-search-btn {
    margin-left: 2rem;
    margin-right: auto;
  }
}
.ant-cascader-menus{
  z-index: 2010 !important;
}

.apponintment-item {
  width: 100%;
  padding: 2px 4px;
  border-radius: 4px;
  // background-color: rgb(255, 255, 255);
  // transition: filter 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  // color: rgb(0, 171, 85);

  &__title-box {
    display: flex;
  }

  &__title {
    margin-left: 5px;
  }

  p {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 16px;
  }
}

.appointment {
  a {
    color: #495057 !important;
  }

  &__space {
    height: 30px;
  }

  .fc-daygrid-event {
    margin-left: 5px !important;
    margin-right: 5px !important;

    &::before {
      z-index: 8;
      opacity: 0.32;
      border: 1px solid currentcolor;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      content: '';
      border-radius: 4px;
      position: absolute;
      box-sizing: border-box;
    }
  }

  .fc-license-message {
    display: none !important;
  }

  .fc-button-group {
    button {
      background-color: #1890ff !important;
      border-color: #fff !important;
    }
  }

  .fc-today-button {
    background-color: #1890ff !important;
    border-color: #fff !important;
  }

  .fc .fc-button-primary:not(:disabled):active:focus,
  .fc .fc-button-primary:not(:disabled).fc-button-active:focus,
  .fc-button:focus,
  .fc-button:active,
  .fc-button:visited {
    box-shadow: 0 0 0 0.2rem #1890ff !important;
  }

  .fc .fc-daygrid-event-harness-abs {
    max-width: 100% !important;
  }
}

.appointment-form {
  .ant-picker,
  textarea {
    width: 100%;
  }

  .ant-skeleton-element {
    width: 100%!important;
  }
}

.appointment__title {
  margin-bottom: 20px;
}

.appointment-filter-box {
  margin-bottom: 20px;

  &__status {
    width: 100%;
  }

  &__submit-btn {
    display: flex !important;
    justify-content: flex-end;
  }

  &__actions {
    display: flex;
    justify-content: center;
    width: 100%;

    // & > *:not(:last-child) {
    //   margin-right: 20px;
    // }
  }
}
.appointment-note{
  &__row{
    margin: 5px 0;
  }
  &__created{
    background-color: #1890ff;
    border-radius: 5px;
    width: 150px;
    height: 30px;
  }
  &__confirmed{
    background-color: #35D2B9;
    border-radius: 5px;
    width: 150px;
    height: 30px;
  }
  &__cancel{
    background-color: #E40017;
    border-radius: 5px;
    width: 150px;
    height: 30px;
  }
  &__arrived{
    background-color: #FEC230;
    border-radius: 5px;
    width: 150px;
    height: 30px;
  }
  &__title{
    text-align: center;
  }
  &__wrapper{
    display: flex !important;
    align-items: center;
    align-content: center;
    padding-left: 10px;
  }
}

.appointment {
  .page-content__left {
    min-width: 350px;
  }
}
.appointment-status {
  height: 22px;
  min-width: 22px;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  padding: 0px 8px;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 8px;
  &.created {
    background-color: #1890ff;
    color:  #f0f0f0,
  }
  &.confirmed {
    background-color: #35D2B9;
    color: darken($color: #e6f7ff, $amount: 50%);
  }
  &.arrived {
    background-color: #FEC230;
    color: #fff;
  }
  &.cancel {
    background-color: #E40017;
    color: #fff;
  }
}