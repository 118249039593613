.partner-status {
  height: 22px;
  min-width: 22px;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  padding: 0px 8px;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 8px;
  &.new {
    background-color: #f0f0f0;
    color: darken($color: #f0f0f0, $amount: 50%);
  }
  &.create {
    background-color: #f0f0f0;
    color: darken($color: #f0f0f0, $amount: 50%);
  }
  &.prepayment_confirmed {
    background-color: #e6f7ff;
    color: darken($color: #e6f7ff, $amount: 50%);
  }
  &.processing {
    background-color: #1890ff;
    color: #fff;
  }
  &.completed {
    background-color: #52c41a;
    color: #fff;
  }
  &.converted {
    background-color: #52c41a;
    color: #fff;
  }
  &.cancelled {
    background-color: #f5222d;
    color: #fff;
  }
}