.page-content {
  .boxStatistc {
    height: 10.5rem;
    padding: 10px 10px 0px 10px;
    .ant-statistic {
      .ant-statistic-content {
        font-size: 16px;
        height: 3.5rem;
        color: #495057;
        .ant-statistic-content-value {
          //   display: block;
        }
      }
      .ant-statistic-title {
        height: 22px;
      }
    }
  }
}
.ant-tooltip-inner__case-chart .ant-tooltip-inner {
  color: #9B9999 !important;
  background-color: white !important;
  width: max-content !important;
  border: none !important;
}
