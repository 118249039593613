 
// Page-title
// 

.page-title-box {
    padding-bottom: $grid-gutter-width;

    .breadcrumb {
        background-color: transparent;
        padding: 0;
    }

    h4 {
        font-weight: 600;
        font-size: 18px !important;
    }

    .page-title-right{
        @media (max-width: 575.98px) {
            display: none;
        }
    }
}

.headerPartner{
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

@media only screen and (max-width: 576px) {	
    .headerPartner{
        flex-direction: column;
    }
}