.pending-bill-item-card {
  .ant-card-body {
    padding:20px 12px;
  }
  .buttonCancel{
    // border-radius: 5px!important;
    font-size : calc(0.05vw + 12px)!important;
    padding : calc(0.05vw + 5px)!important;
    font-weight: 500;
    // background: linear-gradient(179.7deg,#f38e8e -212%,#fa9390 -56.84%,#b53232 99.62%)!important;
  }
  .ant-collapse-header {
    padding-top: 15px !important;
  }

  .ant-descriptions {
    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      padding: 8px !important;
    }
  }

  &__header {

  }

  &__panel {
    &--upload {
      .ant-upload-list-item-card-actions-btn {
        opacity: 1;

        span {
          color: #FF7875;
        }
      }

      .ant-upload-list-item-name {
        max-width: 35vw;
      }
    }
  }
}

.check-in-check-out-modal {
  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;

    img {
      height: 100%;
      object-fit: contain;
    }
  }
}
.row-cancel{
  position: relative;
  &--cancelButton{
    position: absolute!important;
    right: 0%;
    top: -7%;
    z-index: 2;
    padding: calc(0.05vw + 5px)!important;
    font-size: calc(0.05vw + 12px)!important;
  }
}