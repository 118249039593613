
//
// alerts.scss
//

.alert-border{
    background-color: $card-bg;
    border-color: $light;
    border-top: 2px solid $light;
  }

  // alert
.alert-dismissible {
    .btn-close{
      font-size: 10px;
      padding: $alert-padding-y * 1.4 $alert-padding-x;
      box-shadow: none;
      background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
    }
}

.alert-border{
  .btn-close{
    background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
  }
}
  
  @each $color,
  $value in $theme-colors {
      .alert-border-#{$color} {
          border-top-color: $value;
      }
  }


  
.custom-notification{
  cursor: pointer!important;


}
.custom-notification:hover{
  background-color: #f3f3f3;
  transition: 0.5s all;
}
.GroupButtonNotify{
  padding-left: 10px;
  & button{
    border-radius: 30px!important;
    &.activeNotify{
      background-color: #EAF5FF!important;
      & span {
        color: #3D7FF3!important;
      }
    }
  }
}