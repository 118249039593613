.order-details {
  margin-bottom: 24px;
  .order-title {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    align-items: center;
  }
  .prescription-image {
    max-height: 280px;
  }
  .sub-details {
    p {
      strong {
        padding-left: 6px;
      }
    }
  }
  .cancel-reason,
  .cancel-reason i {
    color: red;
  }
  p {
    padding: 6px 0;
    display: flex;
    align-items: center;
    i {
      font-size: 20px;
      margin-right: 10px;
      color: #1a90ff;
    }
  }
}
button + button {
  margin-left: 10px;
}
.order-form {
  .ant-table-content {
    tr > th {
      background-color: #fff;
    }
    .ant-table-tbody {
      > tr > td.ant-table-cell {
        padding: 14px 16px;
      }
      > tr.editing > td.ant-table-cell {
        padding: 10px 6px;
      }
      tr:hover > td,
      tr > td:hover {
        background-color: #fff;
      }
      tr.editable-row {
        td {
          .ant-input-number {
            width: 100%;
          }
          .ant-form-item-explain-error,
          .ant-form-item-explain {
            display: none;
          }
          .remove-action {
            font-size: 22px;
            line-height: 22px;
          }
        }
      }
    }
  }
  .fee-table {
    margin-top: 20px;
    margin-bottom: 20px;
    .ant-table-content {
      .ant-table-tbody {
        > tr > td.ant-table-cell {
          border-bottom: none;
          padding: 8px 16px;
          white-space: nowrap;
        }
        > tr:last-child > td:last-child {
          font-size: 1.35rem;
          font-weight: bold;
        }
      }
    }
  }
  .order-actions {
    button span {
      text-transform: lowercase;
      display: block;
    }
    button span:first-letter {
      text-transform: uppercase;
    }
  }
  .dot-flashing {
    position: relative;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: #1890ff;
    color: #1890ff;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }

  .dot-flashing::before,
  .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-flashing::before {
    left: -8px;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: #1890ff;
    color: #1890ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  .dot-flashing::after {
    left: 8px;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: #1890ff;
    color: #1890ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: #1890ff;
    }
    50%,
    100% {
      background-color: #bae7ff;
    }
  }
}
.ant-image-preview-root {
  .ant-image-preview-mask,
  .ant-image-preview-operations,
  .ant-image-preview-wrap {
    z-index: 2002;
  }
}
