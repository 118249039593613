.tab-branch{
    height: 100%;
    width: 100%;
    padding: 5px!important;
    & .page-content__right{
        flex : 1!important;
        // box-shadow: unset!important;
    }
    &.useBoxShadow > .ant-tabs-nav, &.useBoxShadow > div > .ant-tabs-nav{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1;

    }
    & >.ant-tabs-nav >.ant-tabs-nav-wrap >.ant-tabs-nav-list >.ant-tabs-tab:not(.ant-tabs-tab-active){
            background: #dfe3ec7d!important;
    }
    & .ant-tabs-tab-btn{
        font-weight: 500;
    }
    &.useBoxShadow{
        position: relative;
        .ant-tabs-content-holder{
            height: 100%;
            height: 100%;
            .ant-tabs-content.ant-tabs-content-top{
                height: 100%;
                overflow: auto;
                position: relative;
                &>div{
                    position: relative;
                }
            }
        }
    }
}
.useBoxShadow{
    box-shadow: 0 2px 4px 2px rgba(15, 34, 58, 0.12);
}