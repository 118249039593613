.request-status {
    height: 22px;
    min-width: 22px;
    line-height: 0;
    border-radius: 8px;
    cursor: default;
    align-items: center;
    white-space: nowrap;
    display: inline-flex;
    justify-content: center;
    padding: 0px 8px;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 8px;
    &.CREATE {
      background-color: #f0f0f0;
      color: darken($color: #f0f0f0, $amount: 50%);
    }
    &.PROCESSING {
      background-color: #1890ff;
      color: #fff;
    }
    &.APPROVED {
      background-color: #52c41a;
      color: #fff;
    }
    &.REFUSE {
      background-color: #f5222d;
      color: #fff;
    }
  }