.rowInfo{
    margin-bottom: 10px;
    align-items: center;
}
.course-status {
    height: 22px;
    min-width: 22px;
    line-height: 0;
    border-radius: 8px;
    cursor: default;
    align-items: center;
    white-space: nowrap;
    display: inline-flex;
    justify-content: center;
    padding: 0px 8px;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 8px;
    &.NEW {
      background-color: #f0f0f0;
      color: darken($color: #f0f0f0, $amount: 50%);
    }
    &.SUBMITTED {
      background-color: #1890ff;
      color: #fff;
    }
    &.APPROVED {
      background-color: #52c41a;
      color: #fff;
    }
    &.DENIED {
      background-color: #f5222d;
      color: #fff;
    }
  }
.boxRating{
  margin: 20px 0;
}
.Flex-center-column{
  flex-direction: column!important;
  align-items: center!important;
  margin-top: 20px;
  & span{
    font-size: 0.75rem;
  }
}
.imoteAnimation{
  cursor: pointer;
  transition: 0.5s ease-in-out;
  &:hover{
    transform: scale(1.2);
  }
}
.referralLink{
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 20px;
  padding: 10px 0;
  & .ant-input-group-addon{
    padding: 0!important; 
  }
  &--refresh{
    width: 100px;
  }
}