.jobBanner{
    &--banner{
        width: 100%;
        height: 500px;
        .ant-upload{
            width: 100%!important;
            height: 100%!important;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}