.wh-category-form {
  &__image {
    .ant-upload.ant-upload-select-picture-card {
      margin: 0;
      width: 160px;
      height: 160px;
    }

    .ant-upload-picture-card-wrapper {
      display: flex;
      justify-content: center;
    }
  }
}

.wh-category-by-block-table {
  .completed-row {
    background-color: #f6ffed; // green-1
  }
  .in-progress-row {
    background-color: #e6f7ff; // blue-1
  }
  .cancelled-row {
    background-color: #fff1f0; // red-1
  }
}
.upload-image-icon {
  position: absolute;
  top: 0px;
  right: 220px;
}
.wh-category-short{
  margin-top: 2rem;
  &__image {
    position: relative;
  }
  .icon-comfirm{
    position: absolute;
    top: 20px;
  }
}
