.base-border-box {
  &__divider {
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    &::before {
      border-left: solid thin rgba(0, 0, 0, 0.06);
    }
    &::after {
      border-right: solid thin rgba(0, 0, 0, 0.06);
    }
  }

  &__content {
    border-left: solid thin rgba(0, 0, 0, 0.06);
    border-right: solid thin rgba(0, 0, 0, 0.06);
    border-bottom: solid thin rgba(0, 0, 0, 0.06);
    padding: 0 24px 24px 24px;
  }
}


@media only screen and (max-width: 576px) {	
  .base-border-box {
  
    &__content {
      padding: 5px;
    }
  }
  
}