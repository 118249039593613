$color-primary : #211F78;
$content-width : 100%;
$color-white : white;
$lg : 1024px;
$md : 768px;
$sm : 400px;
.service-options-tooltip{
    color: $color-primary;
    background: #FFFFFF;
    border: 1px solid $color-primary;
    padding: 10px;
  }
  // .ant-modal-body{
  //   background: #F2F7FF;
  // }
  .mark-1-page {
    
    &__options {
      position: relative;
      // background-color: #F2F7FF;
      color: #211F78;
  
      &--content {
        width: $content-width;
        margin-left: auto;
        margin-right: auto;
        border-radius: 24px;
        padding: 30px 0;
        color: $color-white;
      }
  
      &--image {
        position: relative;
        right: 0;
        top: 0;
        img {
          width: 100%;
        }
      }
  
      &--service-name, &--title {
        font-size: 21.3px; // TODO: refine this value
        font-family: Roboto-sm-bold;
        color: #211F78;
      }
  
      &--service-name {
        text-transform: uppercase;
        &__note{
          text-transform:lowercase;
          font-size: 15px;
        }
      }
  
      .instruction {
        font-size: 12.4px; // 14px
        font-style: italic;
        color: #211F78;
      }
  
      .home-service-form {
        &__orderNowButton{
          border-radius: 20px;
          background: linear-gradient(90.54deg, #3a7cff 0.47%, #35d2b9 99.54%);
          color: white;
          padding: 10px 20px;
          height: max-content;
          font-weight: 600;
        }
        &__orderTempButton{
          border-radius: 20px;
          background: linear-gradient(11.97deg, #103393 -0.51%, #3481ff 81.1%, #00d4ff 170.56%);
          color: white;
          padding: 10px 20px;
          height: max-content;
          font-weight: 600;
        }
        &__row {
          .decoration-wrapper {
            display: flex;
            justify-content: flex-end;
            min-width: 33px;
            min-height: 33px;
            margin-left: -40px;
            margin-right: 1rem;
            .decoration-item {
              margin-top: auto;
              margin-bottom: auto;
            }
          }
  
          &--right-col {
  
          }
  
          .ant-switch {
            margin-left: 8px;
          }
  
          &--actions {
            margin-top: 16px;
          }
        }
  
        &__form-item {
          margin-bottom: 16px;
          background-color: transparent;
          // color: #211F78;
  
          label {
            // color: #211F78;
            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
          }
  
          .ant-form-item {
            &-label {
              text-align: left;
              &>label {
                font-size: 14px !important;
              }
  
              .ant-badge {
                font-size: 19.5px !important;
                color: #211F78;
              }
            }
          }
          .ant-form-item-control-input-content {
            display: flex;
            align-items: center;
          }
          &--prefix {
            margin-right: 8px;
          }
          &--date-picker {
            background-color: transparent;
            border: none;
  
            input {
              color: $color-primary;
              font-family: Roboto-medium;
            }
          }
          &--radio-button {
            min-width: 10rem;
            text-align: center;
            border: solid thin #211F78;
            border-radius: 16px;
            color: #211F78;
            font-size: 16px;
  
            span {
              font-family: Roboto-sm-bold !important;
            }
  
            &::before {
              content: none;
            }
            &:not(:first-child) {
              margin-left: 1.5rem;
            }
            &.ant-radio-button-wrapper-checked {
              // border-color: #211F78;
              // background-color: #81B1EC;
              border: none;
              background-image: linear-gradient(11.97deg, #103393 -0.51%, #3481FF 81.1%, #00D4FF 170.56%);
              color: white;
            }
            &:first-child, &:last-child {
              border-radius: 16px;
              border-right-color: #211F78 !important;
            }
          }
          &--advanced-button {
            display: flex;
            align-items: center;
            width: fit-content;
            height: 4.4rem;
            border-radius: 100px !important;
            font-weight: 500;
  
            .ant-checkbox {
              display: none;
            }
  
            &.ant-checkbox-wrapper-checked {
              background: #3481FF !important;
              color: $color-white;
            }
  
            &.ant-radio-button-wrapper-checked {
              background: #3481FF !important;
              color: $color-white;
            }
          }
          &--input-number {
            width: 90px;
            text-align: center;
            border: none;
            background-color: transparent;
            color: #211F78;
  
            input {
              text-align: center;
            }
          }
  
          &--increase-button {
            border: none;
            border-radius: 4px;
            background-color: #B3D1F4;
  
          }
          &--decrease-button {
            border: none;
            border-radius: 4px;
            background-color: #E8EFFA;
          }
        }
  
        &__total {
          &--label, &--value {
            // color: #211F78;
            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
          }
        }
  
        &__primary-button {
          color: $color-white !important;
          font-weight: bold;
          height: auto;
  
          &:hover {
            background: linear-gradient(90.54deg, #3a7cff 0.47%, #35d2b9 99.54%) !important;
          }
          
          &:focus {
            background: linear-gradient(90.54deg, #3a7cff 0.47%, #35d2b9 99.54%) !important;
          }
  
          &:active {
            background: linear-gradient(90.54deg, #3a7cff 0.47%, #35d2b9 99.54%) !important;
          }
  
          &:hover, &:focus, &:active {
            background: linear-gradient(90.54deg, #3a7cff 0.47%, #35d2b9 99.54%) !important;
          }
  
          &[disabled] {
            background: linear-gradient(90.54deg, #3a7cff 0.47%, #35d2b9 99.54%) !important;
          }
        }
        &__secondary-button {
          color: $color-white !important;
          font-weight: bold;
          margin-left: 8px;
  
          &:hover {
            background: linear-gradient(11.97deg, #103393 -0.51%, #3481ff 81.1%, #00d4ff 170.56%);
          }
          
          &:focus {
            background: linear-gradient(11.97deg, #103393 -0.51%, #3481ff 81.1%, #00d4ff 170.56%);
          }
    
          &:active {
            background: linear-gradient(11.97deg, #103393 -0.51%, #3481ff 81.1%, #00d4ff 170.56%);
          }
    
          &:hover, &:focus, &:active {
            background: linear-gradient(11.97deg, #103393 -0.51%, #3481ff 81.1%, #00d4ff 170.56%);
          }
  
          &[disabled] {
            background: linear-gradient(11.97deg, #103393 -0.51%, #3481ff 81.1%, #00d4ff 170.56%);
          }
        }
      }
  
      .mark-1-page__options--advanced {
        margin-top: 36px;
        border-radius: 16px;
        padding: 3.2rem;
        background-color: #638FFF;
  
        .ant-space {
          gap: 20px !important;
          margin-top: 20px;
        }
  
        .instruction {
          padding: 0 3.2rem;
          text-align: left;
          font-size: 22px;
          font-style: normal;
          font-weight: bold;
          color: $color-white;
        }
      }
    }
  }
  
  @media only screen and (max-width: $lg) {	
    .mark-1-page {
      &__options {
        // border: solid thin red; // enable for debug
  
        &--content {
          width: 95%;
        }
  
        &--image {
          img {
          }
        }
  
        .instruction {
        }
  
        .home-service-form {
          &__row {
            .decoration-wrapper {
              margin-left: 0;
            }
            &--right-col {
              margin-top: 5rem;
            }
  
            .ant-switch {
            }
  
            &--actions {
            }
          }
  
          &__form-item {
  
            label {
            }
            .ant-form-item {
            }
            .ant-form-item-label {
            }
            .ant-form-item-control-input-content {
            }
            &--prefix {
            }
            &--date-picker {
            }
            &--radio-button {
              &::before {
              }
              &:not(:first-child) {
              }
              &.ant-radio-button-wrapper-checked {
              }
            }
  
            &--advanced-button {
              &.ant-radio-button-wrapper-checked {
              }
            }
  
            &--input-number {
              input {
              }
            }
  
            &--increase-button {
            }
  
            &--decrease-button {
            }
          }
  
          &__total {
            &--label, &--value {
            }
          }
  
          &__primary-button {
          }
          &__secondary-button {
          }
        }
  
        .mark-1-page__options--advanced {
          .ant-space {
          }
          .instruction {
          }
        }
      }
    }
  }
  
  @media only screen and (max-width: $md) {	
    .mark-1-page {
      &__options {
        // border: solid thin green; // enable for debug
  
        &--content {
          width: 95%;
        }
  
        &--image {
          img {
          }
        }
  
        .instruction {
        }
  
        .home-service-form {
          &__row {
            &--right-col {
            }
  
            .ant-switch {
            }
  
            &--actions {
            }
          }
  
          &__form-item {
  
            label {
            }
  
            .ant-form-item {
  
            }
            .ant-form-item-label {
            }
            .ant-form-item-control-input-content {
            }
            &--prefix {
            }
            &--date-picker {
            }
            &--radio-button {
  
              &::before {
              }
              &:not(:first-child) {
              }
              &.ant-radio-button-wrapper-checked {
              }
            }
            &--advanced-button {
  
              &.ant-radio-button-wrapper-checked {
              }
            }
            &--input-number {
              input {
              }
            }
            &--increase-button {
            }
            &--decrease-button {
            }
          }
  
          &__total {
            &--label, &--value {
            }
          }
  
          &__primary-button {
          }
          &__secondary-button {
          }
        }
  
        .mark-1-page__options--advanced {
          .ant-space {
          }
          .instruction {
          }
        }
      }
    }
  }
  
  
  @media only screen and (max-width: $sm) {
    .mark-1-page {
      &__options {
        // border: solid thin blue; // enable for debug
  
        &--content {
          // width: 95%;
        }
  
        &--image {
          img {
          }
        }
  
        .instruction {
        }
  
        .home-service-form {
          &__row {
            .ant-switch {
            }
  
            &--actions {
            }
  
            &.sm-1-line {
              .ant-form-item-label, .ant-form-item-control {
                flex: 1 1;
                max-width: 50%;
              }
            }
          }
  
          &__form-item {
  
            label {
            }
  
            .ant-form-item {
            }
            .ant-form-item-label {
            }
            .ant-form-item-control-input-content {
            }
            &--prefix {
            }
            &--date-picker {
            }
            &--radio-button {
              &::before {
              }
              &:not(:first-child) {
              }
              &.ant-radio-button-wrapper-checked {
              }
            }
            &--advanced-button {
              &.ant-radio-button-wrapper-checked {
              }
            }
            &--input-number {
              input {
              }
            }
            &--increase-button {
            }
            &--decrease-button {
            }
          }
  
          &__total {
            &--label, &--value {
            }
          }
  
          &__primary-button {
          }
          &__secondary-button {
          }
        }
  
        .mark-1-page__options--advanced {
          .ant-space {
          }
  
          .instruction {
          }
        }
      }
    }
  }
  