$color-primary: #211f78;
.Group-content{
    flex-direction: column;
    &__exchange{
      &__modal{
        & .ant-modal-content{
          border-radius: 20px!important;
        }
        & .ant-modal-footer{
          display: block!important;
        }
      }
      &__title{
        text-align: center;
        color: #211f78!important;
      }
      &__item{
        display: flex;
        flex-direction: column;
        align-items: center;
        // gap: 5px;
        justify-content: center;
        // padding: 30px 0!important;
        margin-bottom: 1rem!important;
        border-radius: 20px;
        width: 100%!important;
        height: auto!important;
        &.unActive{
          background-color: #F0F1F4!important;
          color: $color-primary!important;
        }
        &.active{
          background-color: #3481FF!important;
          color: white!important;
        }
        &__text{
          font-weight: 500;
        }
      }
      &__action{
        margin-top: 10px;
       &>button{
        width: 200px!important;
        // margin: 0 auto !important;
        background-color: #3580FF;
        color: white!important;
        border-radius: 20px!important;
        font-weight: 700;
        height: 44px!important;
        &:hover{
          background-color: #005eff;
        }
        // &:active{
        //   background-color: #E5E5E7;
        // }
       }
      }
    }
  }
  .WithDrawForm{
    margin-top: 20px;
    &--result{
      padding: 20px 10px!important;
    }
  }
  .animationCheck{

    animation: scale .3s ease-in-out;
  }
  .input-amount{
    position: relative;
    width: 100%;
    border-bottom: 1px solid #999;
    font-size: 30px;
    &::after{

      content: 'đ';
      position: absolute;
      right: 0;
      top: 0;

    }
  }
  @keyframes scale {
    0%{
      transform: scale(0);
    }
    80%{
      transform: scale(1.5);
    }
    100%{
      transform: scale(1);
    }

  }
  .bill-status {
    height: 22px;
    min-width: 22px;
    line-height: 0;
    border-radius: 8px;
    cursor: default;
    align-items: center;
    white-space: nowrap;
    display: inline-flex;
    justify-content: center;
    padding: 0px 8px;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 8px;
    &.new {
      background-color: #f0f0f0;
      color: darken($color: #f0f0f0, $amount: 50%);
    }
    &.completed {
      background-color: #52c41a;
      color: #fff;
    }
    &.cancelled {
      background-color: #f5222d;
      color: #fff;
    }
  }