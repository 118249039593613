.shippingVendor-form {
  max-width: 980px;
  margin: 0 auto;

  &__speciality {
    .ant-form-item-label.ant-col-lg-4 {
      max-width: 15.8%;
      margin-right: auto;
    }

    .ant-col-lg-20 {
      max-width: 84.2%;
    }
  }

  .ant-form {
    padding: 30px;
    padding-top: 10px;

    .ant-form-item-label {
      label {
        width: 100%;
      }
    }
  }

  .ant-skeleton {
    width: 100%;
  }

  &__select-langue-btn {
    width: 80px;
    text-align: center;
  }

  &__upload-logo {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin-bottom: 24px;

    .ant-upload.ant-upload-select-picture-card {
      margin: 0;
      width: 160px;
      height: 160px;
    }

    .ant-upload-picture-card-wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }

  .ant-input-number {
    width: 100%;
  }

  .ant-picker {
    width: 182px;
  }

  &__images {
    .ant-upload,
    .ant-upload-list-picture-card-container {
      width: 160px !important;
      height: 160px !important;
    }
  }

  &__submit-box {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    & > * {
      margin: 0 20px;
      min-width: 100px;
    }

    button {
      min-width: 100px;
    }
  }

  .hiden {
    display: none;
  }

  &__description {
    width: 100%;
  }
}

.u-hiden {
  display: none !important;
}
