.page-wraper.orders-page {
  .page-content__left,
  .page-content__right {
    box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  }
  .skeleton-table {
    .ant-skeleton-content .ant-skeleton-title {
      margin-top: 0;
      margin-bottom: 0;
    }
    .ant-table-thead {
      > tr > th {
        padding: 12px 8px;
      }
    }
  }
  .ant-picker {
    width: 100%;
  }
  .custom-table__actions {
    width: 100px;
  }
  .order-status {
    height: 22px;
    min-width: 22px;
    line-height: 0;
    border-radius: 8px;
    cursor: default;
    align-items: center;
    white-space: nowrap;
    display: inline-flex;
    justify-content: center;
    padding: 0px 8px;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 8px;
    &.new {
      background-color: #f0f0f0;
      color: darken($color: #f0f0f0, $amount: 50%);
    }
    &.received {
      background-color: #e6f7ff;
      color: darken($color: #e6f7ff, $amount: 50%);
    }
    &.processing {
      background-color: #fffbe6;
      color: darken($color: #fffbe6, $amount: 50%);
    }
    &.processed {
      background-color: #13c2c2;
      color: #fff;
    }
    &.confirmed {
      background-color: #1890ff;
      color: #fff;
    }
    &.packaged {
      background-color: #d3f261;
      color: darken($color: #d3f261, $amount: 50%);
    }
    &.shipping {
      background-color: #d9f7be;
      color: darken($color: #d9f7be, $amount: 50%);
    }
    &.delivered {
      background-color: #52c41a;
      color: #fff;
    }
    &.cancelled {
      background-color: #f5222d;
      color: #fff;
    }
  }
}
.ant-modal.order-details-modal {
  // width: 90% !important;
  max-width: 1000px;
  .ant-modal-body {
    max-height: 650px;
    overflow: auto;
  }
}