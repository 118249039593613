.registerWhPartnerConfig{
    background: #F2F7FE;
    min-height: 100vh;
    height: 100%;
    padding-top: 1rem;
    &--item{
        position: relative;
        background: #fff;
        box-shadow: 0 5px 10px rgba(58, 124, 255, 0.08);
        border-radius: 16px;
        padding: 1rem;
        display: -moz-box;
        display: flex;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        flex-direction: column;
        -moz-box-align: center;
        margin: 0 auto;
        margin-bottom: 1rem;
        &__image{
            .ant-upload{
                width: 50px!important;
                height: 50px!important;
            }
        }
        &__close{
            position: absolute;
            right: 10px;
            top: 10px;
            z-index: 2;
        }
    }
}
