// 
// _layouts.scss
// 

body[data-layout-size="boxed"] {
    background-color: $boxed-body-bg;
    #layout-wrapper {
        background-color: $body-bg;
        max-width: $boxed-layout-width;
        margin: 0 auto;
        box-shadow: $box-shadow;
    }

    #page-topbar {
        max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
        margin: 0 auto;
    }

    .footer {
        margin: 0 auto;
        max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
    }

    &.vertical-collpsed {
        #page-topbar {
            max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
        }
        .footer {
            max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
        }
    }
}


// Horizontal Boxed Layout

body[data-layout="horizontal"][data-layout-size="boxed"]{
    #page-topbar, #layout-wrapper, .footer {
        max-width: 100%;
    }
    .container-fluid, .navbar-header {
        max-width: $boxed-layout-width;
    }
}

.navbarIcon{
    position: fixed;
    top: $header-height - 60px;
    left: 100px; /* Fallback for browsers that don't support clamp */
    left: calc(5% + 100px); /* Fallback for browsers that don't support clamp */
    left: clamp(100px,5%,200px);
    z-index: 1003; // Higher Header
}