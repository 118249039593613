.wh-service {
  &-detail {
    &__row {
      .ant-divider-with-text {
        margin-bottom: 0 !important;

        &::before {
          width: 16px !important;
          top: -4px !important;
          // border-left: solid thin rgba(0, 0, 0, 0.06);
          border-left: solid thin rgb(240, 240, 240);
        }
        &::after {
          width: 100% !important;
          top: -4px !important;
          // border-right: solid thin rgba(0, 0, 0, 0.06);
          border-right: solid thin rgb(240, 240, 240);
        }
        .ant-divider-inner-text {
          padding: 0 8px;
        }
      }
    }

    &__description {
      &--paragraph {
        border-left: solid thin rgba(0, 0, 0, 0.06);
        border-right: solid thin rgba(0, 0, 0, 0.06);
        border-bottom: solid thin rgba(0, 0, 0, 0.06);
        padding: 0 24px 24px 24px;
      }
    }
  }
}
.copy-icon :hover{
  cursor: pointer;
}
.register-service{
  .ant-select-selector{
    // height: 38px !important;
    // max-height: 38px !important;
  };
  &__disable-row{
    pointer-events: none;
    cursor: no-drop !important;
  }
}

.ant-space-compact-block {
    display: flex !important;
    width: fit-content !important;
}