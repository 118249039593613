.custom-color-badge{
    color: white !important;
}
.tagChecked{
    display: flex!important;
    align-items: center;
    width: 100px;
    & .checked{
        animation: scale 0.5s ease;
    }
}

@keyframes scale {
    0% {
        transform: scale(0);
    }
    100%{
        transform: scale(1);
    }
}

.tagConfirm{
    display: inline-flex!important;
    align-items: center;
    margin: unset!important;
}
.action{
    text-align: end;
}