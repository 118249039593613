.banner-job{
    height: 100%;
    .ant-upload.ant-upload-select-picture-card{
        width: 100%!important;
        height: 200px!important;
        border-radius: 8px;
        img {
            border-radius: 8px;
            width: 100%;
            height: 100%;
        }
    }
}