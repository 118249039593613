@media screen and (max-width: 575px) {
  .workspace-invite-wh-partner-form {
    &__row {
      .ant-col-sm-8 {
        max-width: 33.33333333% !important;
      }
  
      .ant-col-sm-16 {
        max-width: 66.66666667% !important;
      }
    }
  }
}
