.wh-orderTemp{
    &-Box{
        // padding:  10px 15px;
        // box-shadow: 0 2px 4px 3px rgba(123, 126, 128, 0.12);
        display:  flex;
        flex-direction: column;
        align-items: center;    
        width: 800px;
        margin: 0 auto;
        position: relative;
    }
    &-Form {
        width: 100%;
    }
   
}
.loadingWh{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0;
    text-align: center;
    background: #0000003d;
    z-index: 100;
    border-radius: 4px;
}
.WhModal{
    &-modal{
        text-align: center;
    }
    &-title{
       &_success{
        color: green;
        padding-top: 10px;
       }
       &_error{
        color: red;
        padding-top: 10;
       }
    }
    &-Footer{
        width: 100%;
    }
    &-itemFooter{
        flex : 1
    }
}
.listAddressUser{
    &-item{
        &__box{
            max-width: 600px;
            border-radius: 20px;
            padding: 10px 20px;
            background-color: #f2f7ff;
        }
        &__text{
            color: #3481ff;
            font-size: 1rem;
            border-radius: 20px;

            text-align: left;
            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        }
    }
}
.FooterModal{
    >button{
        flex: 1;
    }
}
.remove-padding-addonAfter{
    & .ant-input-group-addon{
        padding: unset!important;
    }
}