.branch-info {
  display: flex;
  justify-content: space-between;

  &__avatar {
    width: 150px;
    height: 150px;
    border-radius: 2px;
    border: 1px dashed #d9d9d9;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
