.wh-service-form {
    max-width: 1280px;
    margin: 0 auto;
  
    .ant-form {
      padding: 50px 40px;
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
        0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
    }
  
    .ant-skeleton {
      width: 100%;
    }
  
    &__select-langue-btn {
      width: 80px;
      text-align: center;
    }
  
    .ant-upload.ant-upload-select-picture-card {
      margin: 0;
      width: 160px;
      height: 160px;
    }
  
    .ant-upload-picture-card-wrapper {
      display: flex;
      justify-content: flex-start;
    }
  
    &__section-title {
      margin: 25px 0;
    }
  
    .ant-input-number {
      width: 100%;
    }
  
    .ant-picker {
      width: 182px;
    }
  
    &__images {
      .ant-upload,
      .ant-upload-list-picture-card-container {
        width: 160px;
        height: 160px;
      }
    }
  
    &__submit-box {
      margin-top: 30px;
      display: flex;
      justify-content: center;
  
      & > * {
        margin: 0 20px;
        min-width: 100px;
      }
  
      button {
        min-width: 100px;
      }
    }
  
    .hiden {
      display: none;
    }
  
    &__description {
      width: 100%;
    }
  
    .top-5 {
      position: relative;
      top: -3px;
    }
  
    &__question {
      &--option {
        .ant-input {
          width: 160px;
        }
      }
  
      &--btn-add-option {
        width: 160px !important;
        height: 160px !important;
      }
    }
  }
  
  .flex-column-center{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }