$navbarPined-height : 50px;
$header-height: 70px;
body {
  background-color: #fff !important;
}

.ant-modal-mask {
  z-index: 2000 !important;
}

.ant-modal-wrap {
  z-index: 2001 !important;
}

.ant-notification{
  z-index: 2003 !important;
}


.ant-picker-dropdown,
.ant-select-dropdown {
  z-index: 2002;
}

html p {
  margin: 0;
  padding: 0;
}

.page-wraper {
  // padding: 2rem;
  // padding-top: 6rem;

  img,
  svg {
    vertical-align: baseline;
  }

  &__title {
    margin-bottom: 1rem;

    h1 {
      font-size: 24px;
    }
  }

  &__header {
    margin-bottom: 1rem;
    min-height: 2rem;

    &--flex-end {
      display: flex;
      justify-content: flex-end;
    }
  }

  .custom-table__actions {
    display: flex;
    width: 60px;
    align-items: center;
    p {
      cursor: pointer;
      color: #1890ff;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    a {
      margin-right: 10px;
    }
  }

  .form__submit-box {
    margin-top: 50px;
    display: flex;
    justify-content: center;

    & > * {
      margin: 0 20px;
      min-width: 100px;
    }

    button {
      min-width: 100px;
    }
  }

  .ant-skeleton {
    width: 100%;
  }
}

.ant-select-item-group {
  font-size: 14px !important;
}

// OVERIDE MINIBLE

@media (min-width: 1200px) {
  body[data-layout='horizontal'] .container-fluid,
  body[data-layout='horizontal'] .container-sm,
  body[data-layout='horizontal'] .container-md,
  body[data-layout='horizontal'] .container-lg,
  body[data-layout='horizontal'] .container-xl,
  body[data-layout='horizontal'] .container-xxl,
  body[data-layout='horizontal'] .navbar-header {
    max-width: 98%;
  }
  body[data-layout='horizontal'] .container-fluid{
    position: relative;
    overflow-y: auto;
  }

  body[data-layout='horizontal'] {
    .page-wraper__title {
      margin-bottom: 1rem;

      h1 {
        font-size: 24px;
      }
    }
  }
}

// FORM
.form-item-horizontal--max-width {
  .ant-form-item-label.ant-col-lg-4 {
    max-width: 15.8%;
    margin-right: auto;
  }

  .ant-col-lg-20 {
    max-width: 84.2%;
  }
}

// NESTED PAGE
.nested-page {
  &__header {
    margin-bottom: 20px;
  }
}

.select-branch-dropdown {
  margin-right: 20px;

  .ant-select-arrow {
    top: 37%;
    right: 20px;

    .navbar-header__location-icon {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .ant-select-selector {
    background-color: rgba(0, 0, 0, 0.07) !important;
    border: none !important;

    input,
    .ant-select-selection-item,
    .ant-select-arrow {
      color: #fff !important;
    }

    .ant-select-selection-item {
      line-height: 32px !important;
    }
  }

  .ant-select-arrow {
    svg {
      color: #fff !important;
    }
  }
}

#page-topbar {
  padding: 0 !important;

  .navbar-header {
    width: 100% !important;
    padding: 0 !important;
  }

  .topnav-wraper {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;

    .topnav {
      margin-top: 0 !important;
    }
  }
}

.page-content {
  padding-top: 0!important;
  margin-top: $navbarPined-height + $header-height + 10px;
  padding-top: 10px!important;
  &__main {
    display: flex;
  }

  &__left {
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    margin-right: 40px;
    padding: 20px;
  }
  .boxShadowNormal{
    box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12)!important;
  }

  &__right {
    padding: 20px;
    width: calc(100% - 250px);
  }
}

.page-content-workSpace {
  margin-top: 70px !important;
}
.ant-message-custom-content {
  display: flex !important;
  align-items: center !important;
}

.form-modal {
  .ant-modal-content {
    max-height: 90vh !important;
    overflow: auto;
  }

  .ant-modal-footer {
    display: none;
  }
}

.ant-message {
  z-index: 3000 !important;
}

.skeleton-table {
  .ant-skeleton-title {
    margin: 3px 0 !important;
  }
}

.apexcharts-datalabels .apexcharts-data-labels text {
  fill: #fff !important;
}

.blink-me {
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.ant-form-item-label > label{
  display: flex;
}
.hospital-form-page-content > h1 {
  margin: 20px 0 40px 18px !important;
}

.wh-degree-table-striped-rows tr:nth-child(2n) td,
.wh-category-table-striped-rows tr:nth-child(2n) td,
.wh-service-table-striped-rows tr:nth-child(2n) td,
.wh-sesionOfDay-table-striped-rows tr:nth-child(2n) td,
.wh-position-table-striped-rows tr:nth-child(2n) td,
.wh-degree-table-striped-rows tr:nth-child(2n) td,
.wh-experience-table-striped-rows tr:nth-child(2n) td,
.wh-tableRanking-table-striped-rows tr:nth-child(2n) td,
.wh-customerType-table-striped-rows tr:nth-child(2n) td,
.wh-service-table-striped-rows tr:nth-child(2n) td,
.wh-envaluate-table-striped-rows tr:nth-child(2n) td {
  background-color: #f6ffed;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.cursor-pointer{
  cursor: pointer!important;
}

.ant-picker-cell-inner{
  width: unset!important;
}

.ant-skeleton-element{
  width: 100%!important;
}

.pined{
  margin-left: 250px!important;
}
