.wh-bill-card-compact {
  cursor: 'pointer';

  &.active {
    background-color: #e6f7ff; // blue-2
    border: 1px solid #1890ff !important;

    p, .anticon {
      color: #1890ff;
    }

  }
}
