.whPackageLevel{
    min-width: 100%;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;

    .cardItemPackage{

        position: relative;
        margin:  0 10px;
        margin-bottom: 12px;
        min-height: 470px;
        min-width: 350px;
        max-width: 300px;
        height: max-content;
        padding: 13px;
        box-shadow: 0px 4px 4px 4px rgba(74, 74, 74, 0.326);
        border-radius: 1rem;
        opacity: 0;
        animation: transtioncard 0.5s forwards var(--inDelay);
        &-btn{
            position: absolute;
            bottom: 15px;
            right: 30px;
        }
        .ant-form-item{
            margin-bottom: 10px;
        }
        .textarea{
            flex-grow: 1;
        }
        label {
            padding-left: 10px;
            font-size: medium;
            font-weight: 500;
            line-height: 1.2;
            font-style: italic;
        }
        textarea,input {
            border-radius: 8px;
            transition: all 0.5s ease-in-out;
            border: 0.5px solid #333333;
        }
        textarea, input[disabled]{
            cursor: default;
            background-color:transparent;
            color: black;
            border: 0.5px solid #d9d9d9;
        }
        button{
            border-radius: 7px;
        }
    }
}
@keyframes transtioncard {
    0%{
        opacity: 0;
        transform: translateY(-30px);
    }
    100%{ 
        transform: translateY(0);
        opacity: 1;
    }
}