.dynamic-delete-button {
    position: relative;
    top: 4px;
    margin: 0 8px;
    color: #999 !important;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .dynamic-delete-button:hover {
    color: rgb(68, 66, 66) !important;
  }
  .dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .service-booking-service{
    &__upload-file {
      .ant-upload-list-item-card-actions-btn {
        opacity: 1;

        span {
          color: #FF7875;
        }
      }

      .ant-upload-list-item-name {
        max-width: 35vw;
      }
    }
  }