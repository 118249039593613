// 
// backgrounds.scss
//


@each $color,
$value in $theme-colors {
    .bg-soft-#{$color} {
        background-color: rgba(($value), 0.25) !important;
    }
}

@each $color,
$value in $theme-colors {
    .bg-gradient-#{$color} {
        background: linear-gradient( to bottom, ($value), rgba(($value), 0.5))!important;

    }
}
.dropdown-menu-In-left .dropdown .dropdown-menu{
    width: 100%!important;
    left: -100%!important;
  }

.dropdown-menu-In-Right .dropdown .dropdown-menu{
    width: 100%!important;
    right: -100%!important;
  }