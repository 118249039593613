.select-date{
  margin-right: 20px;
  margin-top: 20px;
  .ant-row{
    margin-right: 40px !important;
    .ant-form-item{
      margin-bottom: 0px!important;
    }
  }
}
.whVoucher-table__title{
  background-color: transparent !important;
  width: 100%;
}
.ant-table-title{
  background-color: transparent !important;
}
.ant-tooltip{
  z-index: 9999 !important;
}