$bg-primaryBox : #3481ff;
.page-wraper.wh-bills-page {
    .page-content__left,
    .page-content__right {
      box-shadow: 0 2px 4px 2px rgba(15, 34, 58, 0.12);
    }
    .skeleton-table {
      .ant-skeleton-content .ant-skeleton-title {
        margin-top: 0;
        margin-bottom: 0;
      }
      .ant-table-thead {
        > tr > th {
          padding: 12px 8px;
        }
      }
    }
    .ant-picker {
      width: 100%;
    }
    .custom-table__actions {
      width: 100px;
    }
    .bill-status {
      height: 22px;
      min-width: 22px;
      line-height: 0;
      border-radius: 8px;
      cursor: default;
      align-items: center;
      white-space: nowrap;
      display: inline-flex;
      justify-content: center;
      padding: 0px 8px;
      font-size: 0.75rem;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 8px;
      &.new {
        background-color: #f0f0f0;
        color: darken($color: #f0f0f0, $amount: 50%);
      }
      &.create {
        background-color: #f0f0f0;
        color: darken($color: #f0f0f0, $amount: 50%);
      }
      &.prepayment_confirmed {
        background-color: #e6f7ff;
        color: darken($color: #e6f7ff, $amount: 50%);
      }
      &.processing {
        background-color: #1890ff;
        color: #fff;
      }
      &.completed {
        background-color: #52c41a;
        color: #fff;
      }
      &.converted {
        background-color: #52c41a;
        color: #fff;
      }
      &.cancelled {
        background-color: #f5222d;
        color: #fff;
      }
    }
  }
  .ant-modal.wh-bill-details-modal {
    // width: 90% !important;
    max-width: 1200px;
    .ant-modal-title {
      .ant-row {
        padding-right: 30px;
      }
    }
    .ant-modal-body {
      max-height: 650px;
      overflow: auto;
    }
  }
  
  .history-log-item::before{
    content: "\2713";
    margin-right: -10px;
  }
  .history-log-item{
    margin-left: 2rem !important;
  }
  .page-wraper.orders-page {
    .page-content__left,
    .page-content__right {
      box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
    }
    .skeleton-table {
      .ant-skeleton-content .ant-skeleton-title {
        margin-top: 0;
        margin-bottom: 0;
      }
      .ant-table-thead {
        > tr > th {
          padding: 12px 8px;
        }
      }
    }
    .ant-picker {
      width: 100%;
    }
    .custom-table__actions {
      width: 100px;
    }
  }
  .ant-modal.order-details-modal {
    // width: 90% !important;
    max-width: 1000px;
    .ant-modal-body {
      max-height: 650px;
      overflow: auto;
    }
  }
  .box{
    padding: 10px;
    border: 1px solid #999;
    border-radius: 10px;
    margin-top: 20px;
    &-bg{
      padding: 10px;
      background-color: $bg-primaryBox;
      >h5{
        color: white;
      }
    }
  }
  .boxSystem{
    padding: 10px;
    border: 1px solid #999;
    margin: 20px 0;
    background-color: #EEEEEE;
    >.ant-descriptions-view{
      overflow: visible!important;
    }
  }
  .ant-table-title{
    border: solid black 1px!important;
    padding: 5px!important;
    border: none!important;
    background-color: $bg-primaryBox!important;
    font-weight: 500;
    color: white;
  }
.groupButtonHandle{
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin: 20px auto;
  >button{
    width: 100%;
  }
}
.listLogs{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 40px 0;
}
.ant-tooltip-inner {
  // color: black!important;
  // background-color: white!important;
  // width: max-content!important; //remove becaue conflict with news dashboard
  // border: 1px solid black!important;
}
.tooltip-arrow {
  display: none !important;
}
.tag-threeDot{
  overflow: hidden!important;
  max-width: 95%!important;
  text-overflow: ellipsis!important;
  white-space: nowrap!important;
  display: inline-block!important;
}
.wh-service-form {
  max-width: 1280px;
  margin: 0 auto;

  .ant-form {
    padding: 50px 40px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
  }

  .ant-skeleton {
    width: 100%;
  }

  &__select-langue-btn {
    width: 80px;
    text-align: center;
  }

  .ant-upload.ant-upload-select-picture-card {
    margin: 0;
    width: 160px;
    height: 160px;
  }

  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: flex-start;
  }

  &__section-title {
    margin: 25px 0;
  }

  .ant-input-number {
    width: 100%;
  }

  .ant-picker {
    width: 182px;
  }

  &__images {
    .ant-upload,
    .ant-upload-list-picture-card-container {
      width: 160px;
      height: 160px;
    }
  }

  &__submit-box {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    & > * {
      margin: 0 20px;
      min-width: 100px;
    }

    button {
      min-width: 100px;
    }
  }

  .hiden {
    display: none;
  }

  &__description {
    width: 100%;
  }

  .top-5 {
    position: relative;
    top: -3px;
  }

  &__question {
    &--option {
      .ant-input {
        width: 160px;
      }
    }

    &--btn-add-option {
      width: 160px !important;
      height: 160px !important;
    }
  }
}
.ant-input-group-addon{
    border: none!important;
}
.boxStatistc {
  height: 130px;
  & .ant-card-body{
    padding: 24px 10px!important;
  }
  & .ant-statistic-title{
    height: 40px!important;
    font-size: calc(13px + 0.05vw)!important;
  }
  & .ant-statistic-content-value{
    font-size: calc(16px + 0.5vw)!important;
  }
}
.ant-picker-panels > *:not(:first-child) {
  display: none !important;
}
.ant-picker-header-next-btn {
  visibility: visible!important;
}
.ant-picker-header-super-next-btn{
  visibility: visible!important;
}