.wh-appointment-status {
  height: 22px;
  min-width: 22px;
  max-width: max-content;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  align-items: center;
  align-content: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  padding: 0px 8px;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  &.created {
    background-color: #1890ff;
    color: #fff;
  }
  &.confirmed {
    background-color: #35D2B9;
    color: #fff;
  }
  &.arrived {
    background-color: #FEC230;
    color: #fff;
  }
  &.cancel {
    background-color: #E40017;
    color: #fff;
  }
};

.wh-order-status {
  height: 22px;
  min-width: 22px;
  max-width: max-content;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  align-items: center;
  align-content: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  padding: 0px 8px;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  &.new {
    background-color: #f0f0f0;
    color: darken($color: #02030e, $amount: 50%);
  };
  &.received {
    background-color: #f0f0f0;
    color: darken($color: #242ddf, $amount: 50%);
  };
  &.processed {
    background-color: #f0f0f0;
    color: darken($color: #eee952, $amount: 50%);
  };
  &.processing {
    background-color: #f0f0f0;
    color: darken($color: #249451, $amount: 50%);
  };
  &.confirmed {
    background-color: #ce8217;
    color: #fff;
  };
  &.packaged {
    background-color: #e03bae;
    color: #fff;
  };
  &.shipping {
    background-color: #e0a98c;
    color: #fff;
  };
  &.delivered {
    background-color: #60d308;
    color: #fff;
  };
  &.converted {
    background-color: #efe773;
    color: #fff;
  };
  &.cancelled {
    background-color: #f5222d;
    color: #fff;
  };
};