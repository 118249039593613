.wh-partner {
  &-detail {
    &__row {
      &--label {
        font-weight: bold;
      }

      .ant-divider-with-text {
        margin-bottom: 0 !important;

        &::before {
          width: 16px !important;
          top: -4px !important;
          // border-left: solid thin rgba(0, 0, 0, 0.06);
          border-left: solid thin rgb(240, 240, 240);
        }
        &::after {
          width: 100% !important;
          top: -4px !important;
          // border-right: solid thin rgba(0, 0, 0, 0.06);
          border-right: solid thin rgb(240, 240, 240);
        }
        .ant-divider-inner-text {
          padding: 0 8px;
        }
      }
    }

    &__description {
      &--paragraph {
        border-left: solid thin rgba(0, 0, 0, 0.06);
        border-right: solid thin rgba(0, 0, 0, 0.06);
        border-bottom: solid thin rgba(0, 0, 0, 0.06);
        padding: 0 24px 24px 24px;
      }
    }
  }
}
.boxCharacteristic{
  border: 1px solid #999;
  padding: 10px;
  &>div{
    margin: 10px 0;
  }
}
.partner__transaction-status {
  height: 22px;
  min-width: 22px;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  padding: 0px 8px;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 8px;
  &.new {
    background-color: #f0f0f0;
    color: darken($color: #f0f0f0, $amount: 50%);
  }
  &.create {
    background-color: #f0f0f0;
    color: darken($color: #f0f0f0, $amount: 50%);
  }
  &.prepayment_confirmed {
    background-color: #e6f7ff;
    color: darken($color: #e6f7ff, $amount: 50%);
  }
  &.processing {
    background-color: #1890ff;
    color: #fff;
  }
  &.success {
    background-color: #52c41a;
    color: #fff;
  }
  &.converted {
    background-color: #52c41a;
    color: #fff;
  }
  &.cancelled {
    background-color: #f5222d;
    color: #fff;
  }
}
.box-service-partner{
  padding: 0 5px;
  max-height: 10rem;
  overflow: auto;
  // direction: rtl;
  & > div {
    position: sticky;
    top: 0;
    background: white;
  }
  &__item{
    direction: ltr;
  }
}
/* Customize the scrollbar */
.box-service-partner::-webkit-scrollbar {
  width: 10px; /* Set the width of the scrollbar */
  background-color: #F5F5F5; /* Set the background color of the scrollbar */
}

/* Customize the track (the area behind the thumb) */
.box-service-partner::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Customize the thumb (the draggable scrolling element) */
.box-service-partner::-webkit-scrollbar-thumb {
  background-color: #888;
}

/* Customize the thumb when hovered */
.box-service-partner::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.serviceBox{
  max-height: 110px;
  overflow: scroll;
}