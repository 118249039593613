.service-cart-button {
  background-color: rgb(217, 213, 213) !important;
}
.service-button {
  width: 100%;
}
.service-button-update {
  margin: auto;
  margin-top: 20px;
}
