.bill-status {
    height: 22px;
    min-width: 22px;
    line-height: 0;
    border-radius: 8px;
    cursor: default;
    align-items: center;
    white-space: nowrap;
    display: inline-flex;
    justify-content: center;
    padding: 0px 8px;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 8px;
    &.pending {
      background-color: #1890ff;
      color: #fff;
    }
    &.completed {
      background-color: #52c41a;
      color: #fff;
    }
    &.cancel {
      background-color: #f5222d;
      color: #fff;
    }
  }
  .wallet-card{
    position: relative!important;
    overflow: hidden;
    
    &--buttonWithDraw{
      position: absolute!important;
      right: -2%!important;
      top: -1%!important;
      &>button{
        filter: drop-shadow(0 0 5px 1px #333)!important;
      }

    }
  }