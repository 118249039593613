.pending-order {
  .order-card {
    .card-title {
      display: flex;
      justify-content: space-between;
    }
    &.active {
      border-left: 3px solid #1a90ff !important;
    }
    i {
      font-size: 20px;
      margin-right: 6px;
    }
    .phone {
      display: flex;
      align-items: center;
    }
    .time {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
      i {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}
.order-status {
  height: 22px;
  min-width: 22px;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  padding: 0px 8px;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 8px;
  &.new {
    background-color: #f0f0f0;
    color: darken($color: #f0f0f0, $amount: 50%);
  }
  &.received {
    background-color: #e6f7ff;
    color: darken($color: #e6f7ff, $amount: 50%);
  }
  &.processing {
    background-color: #fffbe6;
    color: darken($color: #fffbe6, $amount: 50%);
  }
  &.processed {
    background-color: #13c2c2;
    color: #fff;
  }
  &.confirmed {
    background-color: #1890ff;
    color: #fff;
  }
  &.packaged {
    background-color: #d3f261;
    color: darken($color: #d3f261, $amount: 50%);
  }
  &.shipping {
    background-color: #d9f7be;
    color: darken($color: #d9f7be, $amount: 50%);
  }
  &.delivered {
    background-color: #52c41a;
    color: #fff;
  }
  &.cancelled {
    background-color: #f5222d;
    color: #fff;
  }
}